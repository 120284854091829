
import { BABYLON } from "vue-babylonjs";
import { 
  Scene, 
  PBRMaterial, 
  StandardMaterial,
  NodeMaterial,
  InputBlock,
  CubeTexture, 
  Texture,
  Color3} from "babylonjs";

import Metal from "@/model/materials/metal";
import { SceneController } from "./scene_controller";
import Stone from "@/model/stone/stone";
import File from "@/model/files/file";
import DataManager from "@/model/data_manager"
import { markRaw } from "vue";

import { DiamondMaterialGen } from "@/controllers/materials/diamond_material"

export class MaterialController{
    ///////////////////  SINGLETON  //////////////////////
    private static instance:MaterialController;
    private constructor() { }
    public static getInstance(): MaterialController {
        if (!MaterialController.instance) {
            MaterialController.instance = new MaterialController();
        }
        return MaterialController.instance;
    }

    //////////////////////  DATA  ////////////////////////


    metal?: PBRMaterial;
    settingMetal?: PBRMaterial;
    diamond?: NodeMaterial;
    mainStone?: PBRMaterial;
    skyBox?: CubeTexture;
    scene?: Scene;
    // refraction?: RefractionTexture;


    /////////////////////  PUBLIC  ////////////////////////

    public async loadMaterials(scene:Scene){
        // this.skyBox = new BABYLON.CubeTexture("./skybox/blank", scene);
        // this.skyBox = new BABYLON.CubeTexture("./skybox/sky", scene);
        //new testing skyboxes
        
        // this.skyBox = new BABYLON.CubeTexture("./skybox/beach/", scene);
        // this.skyBox = new BABYLON.CubeTexture("./skybox/chapel/", scene);
        // this.skyBox = new BABYLON.CubeTexture("./skybox/convention/", scene);

        // this.skyBox = new BABYLON.CubeTexture("./skybox/citadella2/", scene);
        // this.skyBox = new BABYLON.CubeTexture("./skybox/citadella/", scene);
        // this.skyBox = new BABYLON.CubeTexture("./skybox/meadow/", scene);


        // this.refraction = new BABYLON.RefractionTexture("th", 1024, scene);
        this.metal = this.loadMetal(scene, 'metal');
        this.settingMetal = this.loadMetal(scene, 'setting_metal');
        this.diamond = await this.loadNodeMaterial(new BABYLON.Color3(1,1,1), 'diamond');
        // this.loadGemStone(scene, 'diamond', 0.2);
        this.mainStone = this.loadGemStone(scene, 'main_gem');
        // await this.loadNodeMaterial(scene, Color3.FromHexString('#00C203'), 'main_gem');
        // 
        this.scene = scene;
    }

    public updateMetal(source:Metal){
      if(this.metal){
        this.updateMetalMaterial(this.metal, source);
      }
    }

    public updateSettingMetal(source:Metal){
      if(this.settingMetal){
        this.updateMetalMaterial(this.settingMetal, source);
      }
    }


    public setStoneColor(stone:Stone){
      if(this.mainStone && stone && stone.gem_color){
        this.updateGemMaterial(this.mainStone, stone);
        // this.updateNodeMaterial(this.mainStone, Color3.FromHexString(stone.gem_color));
      }
    }

    // public setRefractionList(items: any[]){
    //   if(this.refraction){
    //     this.refraction.renderList = [];
    //     for(const i of items){
    //       this.refraction.renderList.push(i);
    //     }
    //   }
    // }

    public applyMaterials(){
      // console.log("APPLYING MATERIALS");
      if(this.scene){
        for (const mesh of this.scene.meshes) {
          if(mesh.isAnInstance){
            continue;
          }
          let material: PBRMaterial | StandardMaterial | NodeMaterial | undefined = this.metal;
          if(mesh.material != undefined || mesh.material != null){
            if(mesh.material.name == 'diamond'){
              material = this.diamond;
            }
            if(mesh.material.name == 'main_gem'){
              material = this.mainStone;
            }
            //TODO: add setting_metal option
            if(mesh.material.name == 'setting_metal'){
              material = this.settingMetal;
            }
            if(mesh.material.name == 'gem_view'){
              material = undefined;
            }
          }
          if(material){
            mesh.material = material;
          }
        }
      }
      // console.log("DONE APPLYING MATERIALS");
    }

    getImageMaterial(image: File, name:string): StandardMaterial {
        const result: StandardMaterial = new BABYLON.StandardMaterial(name, this.scene);
        if(image.filename_disk || image.id){
          const texturePath: string = DataManager.getInstance().getFileUrl(image.filename_disk??image.id??'');
          const texture: Texture = new BABYLON.Texture(texturePath, this.scene);
          texture.hasAlpha = true;
          result.diffuseTexture = texture;
        }
        result.disableLighting = true;
        result.emissiveColor = new Color3(1.0, 1.0, 1.0);
        return result;
    }

    /////////////////////  LOADING  ////////////////////////
    private loadMetal(scene:Scene, name:string):PBRMaterial{
        const result = new BABYLON.PBRMaterial(
            name,
            scene
          );
          if(this.skyBox){
            result.reflectionTexture = this.skyBox;
          }
          result.microSurface = 0.5;
          const m:Metal = new Metal();
          m.primary_color = "#FFFFFF";
          result.maxSimultaneousLights = SceneController.getInstance().totalLights;
          this.updateMetalMaterial(result, m);
          return result;
    }

    
    private updateMetalMaterial(material:PBRMaterial, source:Metal){
      if(material && source.primary_color){
        // const primary = Color3.FromHexString(source.primary_color);
        // material.reflectionColor = primary;
        if(source.base_color){
          const albedo = Color3.FromHexString(source.base_color);
          // new BABYLON.Color3(1.0, 0.0, 0.0);
          // 
          // new BABYLON.Color3(1.0, 0.9, 0.1);
          // material.albedoColor = albedo;

          material.albedoColor = albedo;
          // new BABYLON.Color3(1.0, 0.0, 0.0);
          // Color3.FromHexString(source.base_color);
        }
        material.roughness = 0.3;//source.roughness??0.5;
        material.metallic = 0.8;//source.metallic??1.0;
      }
    }


    private loadGemStone(scene:Scene, name:String, alpha:number = 0.96):PBRMaterial{
        const result = new BABYLON.PBRMaterial(
            name,
            scene
          );
          result.maxSimultaneousLights = SceneController.getInstance().totalLights;
          result.alpha = alpha;
          if(this.skyBox){
            result.reflectionTexture = this.skyBox;
          }
          const stone:Stone = new Stone();
          stone.gem_color = "#ffffff";
          stone.emissive_color = "#ffffff";
          stone.emissive_intensity = 0.1;
          result.backFaceCulling = false;
          result.separateCullingPass = true;
          this.updateGemMaterial(result, stone);
          return result;
    }

    
    private updateGemMaterial(material:PBRMaterial, stone:Stone){
      if(material && stone && stone.gem_color){
        material.albedoColor = Color3.FromHexString(stone.gem_color);
        material.emissiveColor = Color3.FromHexString(stone.emissive_color??stone.gem_color);
        material.emissiveIntensity = stone.emissive_intensity??0.0;
        material.roughness = stone.roughness??0.0;
        material.metallic = stone.metallic??0.98;
      }
    }


    private async loadNodeMaterial(color:Color3, name:string):Promise<NodeMaterial>{

      // const response = await fetch('base_node_material.json');
      // const json = await response.json();
      
      const mat = DiamondMaterialGen.generateDiamondMaterial(color);
      
      // new BABYLON.NodeMaterial(name, scene);
      // mat.loadFromSerialization(json, scene);

      // const mat:NodeMaterial = await BABYLON.NodeMaterial.ParseFromSnippetAsync("KIUSWC#67", scene);
      mat.name = name;
      // const mat:ShaderMaterial = new BABYLON.ShaderMaterial("shader", scene, path, {
      //   attributes: ["position", "normal", "uv"],
      //   uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      // });
      // const baseColorBlock = <InputBlock>mat.getBlockByName("baseColor");
      // baseColorBlock.value = color;

      // this.updateNodeMaterial(mat, color);

      mat.backFaceCulling = false;
      mat.separateCullingPass = true;
      return mat;
    }

    // private updateNodeMaterial(material:NodeMaterial, color:Color3){
    //   if(material && color){
        
    //     // console.log('setting gem node color', color);
    //     const baseColorBlock = <InputBlock>material.getBlockByName('baseColor');
    //     // console.log('previous base color', baseColorBlock.value);
    //     baseColorBlock.value = color;
    //     material.build(false);
    //   }
    // }

}
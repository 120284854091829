import { Directus, ManyItems, PartialItem } from '@directus/sdk';

import Band from './band/band';
import Stone from './stone/stone';
import StoneImage from './stone/stone_image';
import StoneType from './stone/stone_type';
import Metal from './materials/metal';
import File from './files/file';
import Prong from './stone/prong';
import ProngLocation from './stone/prong_location';
import SideStone from './side_stone/side_stone';
import BandMetal from './band/band_metal';
import BandSizeGroup from './sizes/band_size_group';
import BandSize from './sizes/band_size';
import DiamondOption from './band/diamond_option';
import EngravingFontOption from './font/engraving_font_option';
import { OrderController } from '@/controllers/order_controller';
import { FileCache } from '@/controllers/file_cache';
import axios from 'axios';


type CustomTypes = {
	Band: Band;
    Stone: Stone;
    Stone_Image: StoneImage;
    Stone_Type: StoneType;
    Metal: Metal;
    Prong:Prong;
    ProngLocation:ProngLocation;
};

export default class DataManager{

    //////////////////////  SINGLETON  /////////////////////////
    private static instance: DataManager;
    private constructor(){}
    public static getInstance(): DataManager{
        if(!DataManager.instance){
            DataManager.instance = new DataManager();
        }
        return DataManager.instance;
    }

    ///////////////////////  FIELDS  ///////////////////////////
    directus?: Directus<CustomTypes>;
    public path?:string;
    public stones: Stone[] = [];
    public vaultStones: Stone[] = [];
    public selectedStone?: Stone;
    public stoneTypes: StoneType[] = [];

    public metals: Metal[] = [];
    public selectedMetal?: Metal;
    public selectedSettingMetal?: Metal;

    public bands: Band[] = [];
    public selectedBand?: Band;
    public selectedDiamondOption?: DiamondOption;

    public prongs: Prong[] = [];

    public bandSizeGroups: BandSizeGroup[] = [];
    public selectedSize?: BandSize; 

    public engravingFontOptions: EngravingFontOption[] = [];
    public selectedFontOption?: EngravingFontOption;

    public engraving: String = '';
    public additionalRequests: String = '';

    public isWeddingBand: boolean = false;
    public includeWeddingBand: boolean = false;

    public tabHeight: number = 0;

    private callback?:(updateScene:boolean)=>void;

    ///////////////////////  PUBLIC  ///////////////////////////

    public setUpdateCallback(callback:(updateScene:boolean)=>void){
        this.callback = callback;
    }
    public onUpdate(updateScene:boolean = true){
        // console.log('DM on Update');
        if(this.callback !== undefined){
            this.callback(updateScene);
        }
    }

    public setPath(path:string){
        this.path = path;
        this.directus = new Directus<CustomTypes>(path);
    }

    public getFileUrl(source:string){
        return this.getAssetUrl()+source;
    }

    public getAssetUrl(){
        if(this.path === undefined){
            throw new Error("Directus undefined"); 
        }
        return this.path+"/assets/";
    }

    public async updateAll(){
        this.stones = await this.getStones();
        if(this.getMainStones().length > 0){
          this.selectedStone = this.getMainStones()[0];
        }
        this.stoneTypes= await this.getStoneTypes();
        this.metals = await this.getMetals();
        if(this.metals.length > 0){
            this.selectedMetal = this.metals[0];
            this.selectedSettingMetal = this.metals[0];
        }
        this.bands = await this.getBands();
        const main = this.getMainBands();
        if(main.length > 0){
            this.selectedBand = main[0];
        }
        this.prongs = await this.getProngs();
        this.bandSizeGroups = await this.getBandSizeGroups();
        if(this.getBandSizes().length > 0){
            for(const s of this.getBandSizes()){
                if((s.name??'').toLocaleLowerCase() == 'm'){
                    this.selectedSize = s;
                }
            }
        }
        this.engravingFontOptions = await this.getEngravingFontOptions();
        if(this.engravingFontOptions.length > 0){
            this.selectedFontOption = this.engravingFontOptions[0];
        }
    }

    public async getInitalData(onDone:()=>void){
        for(const stoneType of this.stoneTypes){
            await this.preload(stoneType.model);
        }
        let mainStones :Stone[] = this.getMainStones();
        if(mainStones.length > 4){
            mainStones = mainStones.slice(0, 4);
        }
        for(const s of mainStones){
            this.preloadStone(s);
        }


        let mainBands :Band[] = this.getMainBands();
        if(mainBands.length > 4){
            mainBands = mainBands.slice(0, 4);
        }
        for(const b of mainBands){
            this.preloadBand(b);
        }

        //logos
        FileCache.getInstance().queueFile('HartHalo_horizontal_logo_White.png', onDone);
    }

    private preloadStone(stone:Stone){
        if((stone.images?.length??0) > 0 && stone.images![0]){
            FileCache.getInstance().queueFile(stone.images![0].src??'', undefined);
        }
        if(stone.gem_view){
            this.preload(stone.gem_view);
        }
    }

    private preloadBand(band:Band){

        /*
        * Default ring
            * 3d ring model
            * selected prong and side stone models
            * Wedding band(/s)
        */
        //download band
        if(band.model){
            this.preload(band.model);
        }
        //download prongs
        if(band.prong){
            const prong = this.prongByID(band.prong);
            if(prong && prong.model){
                this.preload(prong.model);
            }
        }

        //download sideStones
        if(band.side_stones){
            for(const ss of band.side_stones){
                if(ss.model){
                    this.preload(ss.model);
                }
            }
        }
        //download first image / icon
        if(band.image){
            this.preload(band.image);
        }
        try{
            const wedder :Band|undefined = band.getWeddingBand();
            if(wedder != undefined){
                this.preloadBand(wedder);
            }
        }catch(exception){
            console.log('could not find wedder', exception);
        }
    }

    


    public async preloadModels(){
        for(const stoneType of this.stoneTypes){
            await this.preload(stoneType.model);
        }
        for(const band of this.bands){
            await this.preload(band.model);
            if(band.prong){
                const prong = DataManager.getInstance().prongByID(band.prong);
                if(prong){
                    await this.preload(prong.model);
                }
            }
            if(band.side_stones){
                for(const ss of band.side_stones){
                    await this.preload(ss.model);
                }
            }
        }
    }

    private async preload(file?:File):Promise<void>{
        if(file && file.filename_disk){
            const path = DataManager.getInstance().getAssetUrl()+file.filename_disk;
            // console.log("PRELOADING ASSET", path);
            FileCache.getInstance().queueFile(path, undefined);
            // await fetch(path);
        }
    }


    public async loadFontOptions(){
        // console.log('loading fonts', this.engravingFontOptions);
        for(const option of this.engravingFontOptions){
            if(option.name && option.url){
                // console.log('loading font...', option);
                const font = new FontFace(option.name, 'url('+option.url+')');
                // document.fonts.add(font);
                font.load().then(function(loaded_font){
                    document.fonts.add(loaded_font);
                });
            }
        }

    }



    private getToolTips():Map<string, string>{
        return new Map([
            ['wedding','Please select \'Y\' if you would like the centre stone set high. This will enable a wedding ring to fit snuggly underneath.'],
            ['wedding-include','If you select \'Y\' the selected wedding band will be included in the order.'],
            ['diamonds','Please choose between Natural or Lab Grown (man made) diamonds. Want to know more? Find out more <a href="'+OrderController.getInstance().baseDomain+'/synthetic-lab-created-sapphires" target="_blank"/>here</a>.'],
            ['engraving', 'Our engravings are laser cut onto the inside surface of your ring. Engravings can and will wear off over time with regular wear.</br></br><b>Engraving will not be visualized on your design, however, the final design will be sent to you for approval prior to crafting</b>'],
            ['size', 'We use NZ sizing.  Not sure on your size? Get help <a href="'+OrderController.getInstance().baseDomain+'/ring-sizing-guide/" target="_blank">here</a>.'],
            ['requests', 'We can customise any of these styles, as our rings are all created for you! Please enter any requests here, which will change the total to \'Enquire Now\' and will send us an email for us to be in touch with you.'],
            ['band-price', 'The prices of our bands do change with the fluctuations in precious metal and diamond prices and are reviewed regularly.  If you are a registered user and have selected to be informed, we will endevour to contact you prior to any major price changes.']
        ]);
    }

    public getToolTip(id:string){
        const tips:Map<string, string> = this.getToolTips();
        if(tips.has(id)){
            return tips.get(id);
        }
        return '';
    }

    /////////////////////// DATA GETTERS ////////////////////////////

    public async getStones(): Promise<Array<Stone>> {
        return this.getAll<Stone>("Stone", (source)=>{
            return this.processStone(source);
        }, ["*", 
            "images.Stone_Image_id.*", 
            "measurement.*", 
            "gem_view.*", 
            "meta_data.Meta_Data_id.*"
        ]);
    }

    private processStone(source:any):Stone{
        let images:StoneImage[] = [];
            for(const i of source.images){
                if(i.Stone_Image_id !== undefined && i.Stone_Image_id !== null){
                    images.push(i.Stone_Image_id);
                }
            }

            images = images.sort((i1:StoneImage,i2:StoneImage) => {
                return (i1.position??0) - (i2.position??0);
            });
            source.images = images;
            source = this.subRelated(source, 'meta_data', 'Meta_Data_id');
            return Stone.fromJSON(source);
    }

    public clearVault(){
        this.vaultStones = [];
    }

    
    public async updateVault(token:string):Promise<void>{
        const body = {token:token};
        const url:string = (this.path??'')+'/wp_site_auth/vault';
        const { data, status } = await axios.post(url, body);
        console.log('updating vault...');
        if(data && data.status){
            if(data.status == 'ok'){
                if(data.data.status == 'error'){
                    //TODO: check auth token and login
                }else{
                    this.vaultStones = this.decodeVaultStones(data.data);
                }
            }
        }
    }

    private decodeVaultStones(data:Array<any>):Array<Stone>{
        const res:Array<Stone> = [];
        if(data){
            for(const source of data){
                source.vault = true;
                console.log('adding vault stone', source);
                res.push(this.processStone(source));
                    // Stone.fromJSON(src));
            }
        }
        return res;

    }

    public getMainStones(): Array<Stone>{
        let result:Array<Stone> = [];

        // console.log('adding vault stones...');
        // console.log(this.vaultStones);

        result = result.concat(this.vaultStones);

        for(const stone of this.stones){
            if(!(stone.archived??true) && stone.stone_type){
                result.push(stone);
            }
        }
        return result;
    }

    public async getMetals(): Promise<Array<Metal>> {
        return this.getAll<Metal>("Metal", (source)=>Metal.fromJSON(source));
    }

    public getMainBands():Array<Band>{
        const result:Array<Band> = [];
        for(const band of this.bands){
            if(!(band.is_wedding_band??true) && !(band.archive??true)){
                result.push(band);
            }
        }
        return result;
    }

    public async getBands(): Promise<Array<Band>> {
        return this.getAll<Band>("Band", (source)=>{
            if(source.image != null){
                source.image = File.fromJSON(source.image);
            }
            if(source.model != null){
                source.model = File.fromJSON(source.model);
            }
            if(source.available_metals){
                const metals:Array<BandMetal> = [];
                for(const metal of source.available_metals){
                    metal.metalID = metal.Metal_id;
                    metals.push(BandMetal.fromJSON(metal));
                }
                source.metals = metals;
            }
            if(source.side_stones){
                source.side_stones = this.getSideStones(source.side_stones);
            } 
            source = this.subRelated(source, 'diamond_options', 'Diamond_Option_id', ['price']);
            source = this.subRelated(source, 'matching_wedding_bands', 'related_Band_id');
            return Band.fromJSON(source);
        }, [
            "*", 
            "image.*.*", 
            "model.*.*", 
            "available_metals.*", 
            "prong", 
            "side_stones.*.*", 
            "side_stones.*.locations.*.*", 
            "side_stones.*.model.*",
            "diamond_options.*.*",
            "matching_wedding_bands.*.*",
            "matching_wedding_bands.*.model.*.*"
        ]);
    }

    private getSideStones(source:any){
        const result:Array<SideStone> = [];
        for(const s of source){
            if(s.Side_Stone_id){
                const stoneSource = s.Side_Stone_id;
                if(stoneSource.locations){
                    stoneSource.locations = this.getLocations(stoneSource.locations);
                }
                result.push(SideStone.fromJSON(stoneSource));
            }
        }
        return result;
    }

    private getLocations(locations: any){
        const result:Array<ProngLocation> = [];
        for(const l of locations){
            if(l.Prong_Location_id){
                const locationSource = l.Prong_Location_id;
                result.push(ProngLocation.fromJSON(locationSource));
            }
        }
        return result;
    }

    public async getStoneTypes(): Promise<Array<StoneType>> {
        return this.getAll<StoneType>("Stone_Type", 
            (source)=>{
                source = this.subRelated(source, 'prong_locations', 'Prong_Location_id');
                return StoneType.fromJSON(source);
            }, 
            ["*", "model.*.*", "prong_locations.Prong_Location_id.*", "measurement.*.*"]); 
    }

    public async getProngs(): Promise<Array<Prong>> {
        return this.getAll<Prong>("Prong", (source)=>Prong.fromJSON(source), ["*", "model.*.*"]);
    }

    public async getBandSizeGroups(): Promise<Array<BandSizeGroup>>{
        return this.getAll<BandSizeGroup>("Band_Size_Group", (source)=>{
            source = this.subRelated(source, 'sizes', 'Band_Size_id');
            return BandSizeGroup.fromJSON(source);
        }, ["*", "sizes.*.*"]);
    }

    public async getEngravingFontOptions() : Promise<Array<EngravingFontOption>>{
        return this.getAll<EngravingFontOption>("Engraving_Font_Option", EngravingFontOption.fromJSON);
    }

    private subRelated(source:Object, field:string, relatedField:string, alsoInclude:Array<string> = []){
        if(source){
            const sourceMap:Map<string, any> = new Map(Object.entries(source));
            if(sourceMap.get(field)){
                const result:Map<string, any>[] = [];
                for(const r of sourceMap.get(field)){
                    const innerMap:Map<string, any> = new Map(Object.entries(r));
                    if(innerMap.get(relatedField)){
                        const res = innerMap.get(relatedField);
                        if(alsoInclude.length > 0){
                            for(const a of alsoInclude){
                                res[a] = innerMap.get(a);
                            }
                        }
                        result.push(res);
                    }
                }
                // source[field] = result;
                sourceMap.set(field, result);
            }
            return Object.fromEntries(sourceMap);
        }        
        return source;
        
    }

    public getAvailableMetals(): Array<Metal>{
        const result:Array<Metal> = [];
        if(this.selectedBand && this.selectedBand.metals){
            for(const bandMetal of this.selectedBand.metals){
                if(bandMetal.metalID){
                    const metal:Metal | null = this.metalByID(bandMetal.metalID);
                    if(metal){
                        result.push(metal);
                    }
                }
            }
        }
        return result;
    }

    public metalByID(metalID:number): Metal | null{
        for(const metal of this.metals){
            if(metal.id == metalID){
                return metal;
            }
        }
        return null;
    }


    public stoneByID(stoneID:number): Stone | null{
        for(const stone of this.getMainStones()){
            if(stone.id == stoneID){
                return stone;
            }
        }
        return null;
    }

    public bandByID(bandID:number): Band | null{
        for(const band of this.getMainBands()){
            if(band.id == bandID){
                return band;
            }
        }
        return null;
    }

    public stoneTypeByID(stoneTypeID:number): StoneType | null{
        for(const stoneType of this.stoneTypes){
            if(stoneType.id == stoneTypeID){
                return stoneType;
            }
        }
        return null;
    }

    public prongByID(prongID:number): Prong | null{
        for(const prong of this.prongs){
            if(prong.id == prongID){
                return prong;
            }
        }
        return null;
    }

    public previousBand(){
        const mainBands = this.getMainBands();
        if(mainBands && this.selectedBand){
            let index = mainBands.indexOf(this.selectedBand);
            if(index == 0){
                index = mainBands.length;
            }
            this.selectedBand = mainBands[index-1];
        }
    }

    public nextBand(){
        const mainBands = this.getMainBands();
        if(mainBands && this.selectedBand){
            let index = mainBands.indexOf(this.selectedBand);
            if(index == mainBands.length-1){
                index = -1;
            }
            this.selectedBand = mainBands[index+1];
        }
    }

    public getBandSizes():Array<BandSize>{
        let res:Array<BandSize> = [];
        for(const g of this.bandSizeGroups){
            if(g.sizes){
                res = res.concat(g.sizes);
            }
        }
        return res.sort((a:BandSize, b:BandSize)=>(a.size??0.0) - (b.size??0.0));
    }

    public getSizeGroupFor(selectedSize: BandSize): BandSizeGroup|undefined {
        for(const group of this.bandSizeGroups){
            if(group.sizes && group.sizes.includes(selectedSize)){
                return group;
            }
        }
        return undefined;
    }

    ///////////////////////    UTIL     ///////////////////////////

    public static removeHtml(source:string):string{
        return source.replace(/<\/?[^>]+(>|$)/g, "");
    }

    ///////////////////////  DATA SYNC  ///////////////////////////

    private async getAll<T>(type:string, builder:(source:any)=>T, fields:string[] = ['*']) : Promise<Array<T>>{
        if(this.directus === undefined){
            throw new Error("Directus undefined"); 
        }
        // console.log("getting all "+type);
        const source = this.directus.items(type);
        const result = await source.readByQuery({
            limit: -1,
            fields: fields
        });
        const res:Array<T> = [];
        if(result.data != undefined){
            for(const obj of result.data){
                res.push(builder(obj));
            }
        }
        return res;
    } 
}
